import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import toTopArrow from '../images/to-top-arrow.svg';

const buttonHeightWidth = "50";

const ToTopDiv = styled.div`
  width: ${buttonHeightWidth}px;
  height: ${buttonHeightWidth}px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 15000;
  .CookieConsent + & { bottom: 180px; } 

  @media (min-width: 800px) {
    right: 30px;
    bottom: 30px;
    .CookieConsent + & { bottom: 130px; }
  }
`

const ToTopButton = styled.button`
  width: ${buttonHeightWidth}px;
  height: ${buttonHeightWidth}px;
  line-height: 0;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
`

const ScrollToTop = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true);

  const handleScroll = () => {
    if  (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <ToTopDiv className="to-top-container">
      {show &&
        <ToTopButton onClick={handleClick} className='toTop' >
          <img src={toTopArrow} alt="Scroll to top" width={buttonHeightWidth} height={buttonHeightWidth} style={{ marginBottom: '0' }}/>
        </ToTopButton>
      }
    </ToTopDiv>
  )
}

export default ScrollToTop;