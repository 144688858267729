import React from 'react';

import FlexContainer from './flexContainer';
import aacucLogo from '../images/footer/aacuc-logo.svg';
import civicLogo from '../images/footer/civic-logo.svg';
import lgfcuLogo from '../images/footer/lgfcu-logo.svg';

const Footer = () => (
  <footer>
    <div className="section-container">
      <FlexContainer justifyContent="space-evenly" className="founder-image-container">
        <a href="https://www.aacuc.org/" target="_blank"><img src={aacucLogo} width="120" alt="AACUC" /></a>
        <a href="https://www.civicfcu.org/" target="_blank"><img src={civicLogo} width="150" alt="Civic FCU" /></a>
        <a href="https://www.lgfcu.org/" target="_blank"><img src={lgfcuLogo} width="185" alt="LGFCU" className="lgfcu-logo-image"/></a>
      </FlexContainer>
    </div>    
  </footer>  
);

export default Footer;