import React from 'react';
import { Link } from 'gatsby';

const NavbarLinks = () => {

  return (
    <>   
      <Link to="/#principles">Cooperative Principles</Link>
      <Link to="/#eighth-principle">8th Cooperative Principle</Link>
      <Link to="/#business-case">Business Case for DEI</Link>
      <Link to="/#why">Why DEI Matters</Link>
      <Link to="/#resolution">Resolution</Link>
      <Link to="/#support">Show of Support</Link>   
    </>    
  )
}

export default NavbarLinks;