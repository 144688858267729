import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import NavbarLinks from './navbarLinks';
import HomeIcon from '../images/inline/home-icon.svg';
import '@fontsource/open-sans-condensed/700.css';

const mobileBreak = '1300px';

const Navigation = styled.nav`  
  font-family: "Open Sans Condensed", Helvetica, sans-serif;
  font-weight: 700;
  position: absolute;
  top: 0;
  /* background: #0e78b2; */
  background: #006ead;

  & a {
  font-size: 20px;
  text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: ${mobileBreak}) {
    position: fixed;
    background: transparent;
  }
`;
const Toggle = styled.button`
  padding: 0 .2rem .2rem .2rem;
  margin-top: 2rem;
  color: #fff;
  background: transparent;
  border: 1px solid transparent;
  font-size: 3em;
  cursor: pointer;
  line-height: 1;
  position: relative;
  left: 2rem;
  /* position: absolute; */
  /* right: 0; */
  /* top: 1.45rem; */

  &[aria-expanded="true"] { padding: 0 .6rem .2rem .6rem; }
  
  @media (min-width: 1000px) {
    
  }

  @media (min-width: ${mobileBreak}) {
    display: none;
  }

  &:focus {
    outline: none;
    border: 1px dashed #fff;
  }
`;
const MobileNavbox = styled.div`
  margin: 1em 0 0 0;
  display: flex;
  flex-direction: column;
  background-color: #e2eff6;
  width: 100vw;
  text-align: center;

  & a {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #006ead;

    &:last-of-type { border-bottom: 0; }
  }

  @media (min-width: ${mobileBreak}) {
    display: none;
  }
`;
const Hamburger = styled.span`
`;

const FullMenu = styled.div`
  background: #fff;
  width: 100vw;
  padding: 24px 2.5rem 24px 2rem;
  border-bottom: 5px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .5s ease-in;
  /* display: none; */

  & a {
    margin-right: 1em;

    &:last-child { margin-right: 0; }
  }

  & svg .house-shape { fill: #006ead; }

  &.outside-header { 
    background: #006ead; 
    transition: all .5s ease-in;

    & a { color: #fff; 
    }

    & svg .house-shape { fill: #fff; }
  }

  @media (max-width: ${mobileBreak}) {
    display: none;
  }
`

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Navigation>
      <Toggle
        navbarOpen={navbarOpen}
        aria-expanded={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger>&#215;</Hamburger> : <Hamburger>&#9776;</Hamburger>}
      </Toggle>
      {navbarOpen ? (
        <MobileNavbox id="main-menu">
          <Link to="/">Home</Link>
          <NavbarLinks />
        </MobileNavbox>
      ) : (
        <MobileNavbox id="main-menu" className="visually-hidden">
          <Link to="/">Home</Link>
          <NavbarLinks />
        </MobileNavbox>
      )}
      <FullMenu className="full-menu">
        <Link to="/"><HomeIcon /></Link>
        <NavbarLinks />
      </FullMenu>
    </Navigation>
  )
}

export default Navbar;