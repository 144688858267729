import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import CookieConsent from  'react-cookie-consent';

import Header from './header';
import Footer from './footer';
import ScrollToTop from '../components/scrollToTop';
import LineArtDiv from '../components/lineArtDiv';
import '@fontsource/roboto-slab';
import './layout.scss';

const Layout = ({ isHomePage=false, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header isHomePage={isHomePage} siteTitle={data.site.siteMetadata.title} />
        <main>    
          {children}
        </main>
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="X"
          style={{ 
            fontSize: "16px",
            paddingTop: "20px",
            paddingBottom: "20px",
            color: "#fff", 
            background: "#1b3769", 
            borderTop: "#fff solid 3px",
            alignItems: "center" 
          }}
          contentStyle={{
            maxWidth: "calc(1052px + 2rem)",
            padding: "0 1rem",
            margin: "0 auto",
          }}
          buttonStyle={{ 
            color: "#fff", 
            fontWeight: "bold",
            background: "transparent" 
          }}
          expires={0}
        >
          We use cookies to give you the best online experience and to analyze our traffic. You consent to our cookies if you continue to use our website.<br />
          Read our <Link 
            style={{ color: "#fff", 
              fontWeight: "bold", 
              textDecoration: "underline"  
            }} 
            to="/privacy-policy/">Privacy Notice and Cookie Policy
          </Link>.
        </CookieConsent>
        <ScrollToTop showBelow={250} />
        <LineArtDiv style={{ zIndex: '5000' }} className="line-art-div" />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
