import styled from 'styled-components';
import LineArt from '../images/dei-line-art.svg';

const LineArtDiv = styled.div`
  background-image: url(${LineArt});
  background-position: 0 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100px;
  position: fixed;
  width: 100px;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5000;

  @media (max-width: 1300px) {
    display: none;
  }
`;

export default LineArtDiv;