import React from 'react';
import Navbar from './navbar';
import styled from 'styled-components';
import backgroundImage from '../images/header-dei-8-graphic.svg';
import { InView } from 'react-intersection-observer';

const HeaderContainer = styled.div`
  color: #fff;
  margin: 0 auto;
  max-width: 1052px; /* 1052 + 200 for background image */
  padding-top: 1.45rem;
  padding-bottom: 1.45rem;
  padding-right: 0;
  padding-left: 2rem;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  background-image: url(${backgroundImage});
  /* background-color: #0e78b2; */
  background-color: #006ead;
  background-repeat: no-repeat;
  /* background-position: calc(100% - 1px) 0; */
  background-position: 100% 0;
  background-size: 220px;

  & h1, .h2-style {
    font-size: 34px;
    margin: 5em 0 1rem 0;
    color: inherit;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
    position: relative;
    bottom: 0;
  }

  & .h2-style { 
    max-width: 75%;
    font-weight: bold;
    margin: 110px 0 .5em 0; 
  }

  @media (min-width: 400px) {
    background-position: calc(100% + 1px) 0;
    background-size: auto calc(100% + 1px);
  }

  @media (min-width: 575px) {
    & h1, & .h2-style { font-size: 50px; }
  }

  @media (min-width: 1000px) {
    /* background-size: auto; */

    & h1, & .h2-style {
      font-size: 78px;
      margin: 117px 0;
      color: inherit;
      text-rendering: optimizeLegibility;
      line-height: 1.1;
    }

    & .h2-style { 
      max-width: none;
      font-size: 60px; 
      margin-bottom: .5em;
    }
  }

  @media (min-width: 1100px) {
    padding-left: 0;
    /* padding-left: 100px; */
  }

  @media (min-width: 1300px) {

    & .h2-style { margin-top: .5em; }
  }
`;

const Header = ({ isHomePage }) => (
  <>
  <InView as="header" className="header" onChange={(inView) => {
    /* toggle class on full navbar when header scrolls out of/in view */
    if (!inView) {
      document.querySelector('.full-menu').classList.add('outside-header');
    } else {
      document.querySelector('.full-menu').classList.remove('outside-header');

      /* a weird hack necessary to keep line-art-div behind header after scroll up */
      let zIndexValue = document.querySelector('.line-art-div').style.zIndex;
      if (zIndexValue === '4000') {
        document.querySelector('.line-art-div').style.zIndex = '5000';
      } else {
        document.querySelector('.line-art-div').style.zIndex = '4000';
      }
    }
  }}
  style={{
    zIndex: '11000',
    position: 'relative'
  }}>  
  
    <HeaderContainer>
      { isHomePage && (
        <h1>
          DIVERSITY, <br/>
          EQUITY AND <br/>
          INCLUSION TALKS
        </h1>
      )}
      { !isHomePage && (
        <span class="h2-style">DIVERSITY, EQUITY <br/>
        AND INCLUSION TALKS</span>
      )}
    </HeaderContainer>
  
    <Navbar isHomePage={isHomePage} />
  </InView>
  </>
);

export default Header;
