import React from 'react';
import styled from 'styled-components';

const FlexDiv = styled.div`
  display: flex;
  align-items: ${props => props.alignItems || "center"};
  justify-content: ${props => props.justifyContent  || "flex-start"};
`;

const FlexContainer = ({ alignItems, justifyContent, className, children }) => (
  <FlexDiv alignItems={alignItems} justifyContent={justifyContent} className={(className || '') + " flex-container"}>
    {children}
  </FlexDiv>
);

export default FlexContainer;